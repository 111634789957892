/* Global variables */
var evenDigitCheckUtils;

function isFunction(func) {
    return typeof func === 'function';
}
window.isFunction = isFunction;

function getURLParameter(name, urlQuery) {
    urlQuery = urlQuery || location.search;
    return (
        decodeURIComponent(
            (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
                urlQuery
            ) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
    );
}
window.getURLParameter = getURLParameter;

function stopEventPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
}
window.stopEventPropagation = stopEventPropagation;

function scrollToTarget($target) {
    $('html, body').animate({ scrollTop: 0 }, 0);
    $('html, body').animate(
        {
            scrollTop: $target ? $target.offset().top - 90 : 0,
        },
        500
    );
}

//Uniqe ID Generator
function Generator() {}

Generator.prototype.rand = Math.floor(Math.random() * 26) + Date.now();

Generator.prototype.getId = function () {
    return 'el-' + this.rand++;
};
// async scripload width callback

function initPanoramaImages() {
    if (document.querySelector('.js-panorama-image')) {
        var panoramaScriptUrl =
            '/.resources/corp-2016/themes/js/pannellum.min.js';
        var panoramaStyleUrl = '/.resources/corp-2016/themes/css/pannellum.css';

        loadCss(panoramaStyleUrl);
        loadScript(panoramaScriptUrl, function () {
            if (!document.querySelector('js-pannellum-style')) {
                var styleElement = document.createElement('style');
                styleElement.appendChild(
                    document.createTextNode(
                        '.panorama { width: 100%;height: 400px;}'
                    )
                );
                document.head.appendChild(styleElement);
            }

            $('.js-panorama-image').panoramaImagesPlugin();
        });
        // , function () {
        // 	console.log("pannellum not loaded");
        // });
    }
}
window.initPanoramaImages = initPanoramaImages;

function getResourceUrl(basename, extension) {
    return __webpack_public_path__ + basename + (__resource_suffix__ || '') + extension;
}

function loadCss(url) {
    $('head').append(
        "<link rel='stylesheet' type='text/css' href='" + url + "' />"
    );
}

function loadScript(src, onLoadCallback, id, type) {
    onLoadCallback = onLoadCallback || null;

    var script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.defer = true;
    if (id) script.id = id;
    if (type && type == 'module') script.type = 'module';
    if (type && type == 'nomodule') script.noModule = true;

    script.addEventListener('load', function () {
        if (onLoadCallback) onLoadCallback();
    });

    document.body.appendChild(script);
}

// function loadScript(url, onsuccess, onerror) {
// 	$.ajax({
// 		url: url,
// 		dataType: "script",
// 		cache: false
// 	}).done(function( script, textStatus ) {
// 			// File/url exists
// 			//console.log("JS Loader: file exists, executing $.getScript "+url)

// 				if (onsuccess) {
// 					//console.log("JS Loader: Ok, loaded. Calling onsuccess() for " + url);
// 					onsuccess();
// 					//console.log("JS Loader: done with onsuccess() for " + url);
// 				} else {
// 					//console.log("JS Loader: Ok, loaded, no onsuccess() callback " + url)
// 				}

// 		}).fail(function(jqxhr, settings, exception) {
// 			console.log(exception);
// 		// File/url does not exist
// 		if (onerror) {
// 			//console.error("JS Loader: probably 404 not found. Not calling $.getScript. Calling onerror() for " + url);
// 			onerror();
// 			//console.error("JS Loader: done with onerror() for " + url);
// 		} else {
// 			//console.error("JS Loader: probably 404 not found. Not calling $.getScript. No onerror() callback " + url);
// 		}
// 	});
// }

window.idGen = new Generator();

/* Diese Funktion wird bei jQueryDomReady  aufgerufen */
function jqueryOnDomReady(event) {
    var $html = $('html');
    var language = $html.attr('lang');
    var $konzernNavLink = $('#konzern-nav-link');
    var $konzernNav = $('.konzern-nav');

    document.documentElement.className =
        document.documentElement.className.replace(/\bno-js\b/, 'js');

    /* Such-Icon in Navigation verschieben, wenn keine Sprachnavigation */
    var $langNav = $('.lang-nav,.lang-dd-nav');
    if (!$langNav.length) {
        $('.service-nav').css('right', '40px');
        $('#service-nav-search-icon').css('border-right', 'none');
    }

    /* Setze touch oder no-touch css-Klasse in HTML-Tag  */
    if ('ontouchstart' in document.documentElement) {
        $html.addClass('touch');
    } else {
        $html.addClass('no-touch');
    }

    $('.vertical-scroll').on('touchmove', function (event) {
        //event.stopPropagation()
    });
    $konzernNavLink.attr('aria-expanded', 'false');
    $konzernNavLink.attr('aria-controls', 'konzern-nav');
    $konzernNav.attr('id', 'konzern-nav');
    $konzernNavLink.on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();

        if ($html.hasClass('konzern-nav-open')) {
            closeKonzernNav();
        } else {
            openKonzernNav();
        }
    });

    $('.button-close-konzern-nav').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();

        if ($html.hasClass('konzern-nav-open')) {
            closeKonzernNav();
        }
    });

    $konzernNavLink.on('blur', function () {
        setTimeout(function () {
            if (
                $html.hasClass('konzern-nav-open') &&
                $konzernNav.find(':focus').length == 0 &&
                !$konzernNav.is(':focus')
            ) {
                closeKonzernNav();
            }
        }, 30);
    });

    function closeKonzernNav() {
        $html.removeClass('konzern-nav-open');
        $konzernNavLink.attr('aria-expanded', 'false');
        $konzernNav.slideUp(300);
    }

    function openKonzernNav() {
        $('.konzern-nav').slideDown(200, function (event) {
            $html.addClass('konzern-nav-open');
            $konzernNavLink.attr('aria-expanded', 'true');
            $konzernNav.trigger('focus');

            if ($(window).width() >= 992) {
                var lastFocusableElement = $konzernNav.find('a').last();
            } else {
                var focusableElements = $konzernNav.find(
                    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
                );
                var lastFocusableElement =
                    focusableElements[focusableElements.length - 1];
            }

            $(lastFocusableElement).on('blur', function (event) {
                $konzernNavLink.trigger('focus');
            });

            var firstInKonzernNav = $konzernNav.find('a').first();
            firstInKonzernNav.on('keydown', function (e) {
                if (e.keyCode == 9 && e.shiftKey == true) {
                    e.preventDefault();

                    $html.removeClass('konzern-nav-open');
                    $konzernNavLink.attr('aria-expanded', 'false');
                    $konzernNav.slideUp(300);
                    $konzernNavLink.trigger('focus');
                }
            });
        });
    }

    /* Plugin to bind initial events */
    $.fn.eventsHandlerPlugin();
    /* Plugin für Hauptnavigation    */
    $('header:not(.sticky-header)').headerPlugin();
    /* Plugin für Hauptnavigation    */
    $('.site-nav').navPlugin();
    $('.service-nav').serviceNavPlugin();
    $('.country-nav').countryNavPlugin();
    $('.lang-dd-nav').langDdNavPlugin();

    $('#off-canvas-menu').offcanvasPlugin({ locale: language });

    $('.js-gallery').gallery2Plugin();

    $('.js-dci-widget').siteimproveWidgetPlugin();

    /* Plugin für Accordeons    */
    $('.accordeon:not(.edit-mode)').accordeonPlugin();

    /* Plugin für Waterfall-Pages    */
    if ($('.waterfall-template:not(.edit-mode)').length) {
        $(
            '.waterfall-template:not(.edit-mode)'
        ).waterfallVideosDisclaimerPlugin();
        $('.waterfall-template:not(.edit-mode)').waterfallPlugin();
    }

    /* Plugin für Panorama-Images */
    initPanoramaImages();

    //Scroll-Classes
    $('.js-scroll-classes').scrollClasses();

    /* Plugin für Kleinanzeigen    */
    //$(".js-kleinanzeigen").kleinanzeigenPlugin();
    $('.js-tablefilter').tableFilterPlugin();

    $('.js-accordeon__toggler').accordeonSimplePlugin();

    /* Plugin für Immo-FIlter inkl. Suchagent    */
    $('.js-immo-filter').immoFilterPlugin();

    /* Plugin für Disclaimer-Videos    */
    $('body').videoDisclaimerPlugin();

    /* Plugin für LazyLoad-Images    */
    $('.js-lazy-image').lazyLoadImagesPlugin();

    /* Plugin für LazyLoad-Frames    */
    $('.js-lazy-frame').lazyLoadFramesPlugin();

    /* Plugin für Laden von Videos mit Vorschaubild */
    $('.js-video-preview').videoPreviewPlugin();

    /* Plugin für FadeinTeaser    */
    $('.magazine .md-teaser-4').fadeInTeaserPlugin();

    /* Plugin für Suedstrecke-Quiz    */
    if ($('#slickQuiz').length) {
        var slickquizSrc = '/.resources/corp-2016/themes/js/slick-quiz.min.js';

        loadScript(slickquizSrc, function () {
            $('#slickQuiz').quizPlugin();
        });
        // , function () {
        // 	console.log("SlickQuiz not loaded");
        // });
    }

    /* Plugin für Stoerungsmeldungen f Plattformen    */
    $('#stoerungsmeldungen').stoerungsmeldungenPlugin();

    //CK-Editor Icons-Fix
    $('[class^="md-button-ic-"]').each(function (i, element) {
        var span = $('<span />');
        //span.html("&nbsp;");
        span.addClass($(element).data('icon'));
        $(element).prepend(span);
        $(element).removeClass();
        $(element).addClass('md-button');
    });

    $('.visual-center').imgVisualCenterPlugin();
    $('.content').hyperlinkIconsPlugin();
    $('.slider:not(.slider--magazine):not(.edit-mode)').sliderPlugin();

    $('.slider--magazine:not(.edit-mode)').sliderPlugin({ fullscreen: true });

    $('.mup-category-list').mashupSocialMediaCheckPlugin();
    /* If we are displaying the presse mashups, this plugin will then check the URL parameters and check if there is a
     * preselected category, initializing the "Category" select in that case
     */
    //$(".press-mup-filter .container .row .col-1 form select[name='category']").presseMashupPlugin();

    $('.md-mashup').mashupPlugin();
    $('.contact-us').contactUsPlugin();

    if ($('#skzPanel').length > 0) {
        evenDigitCheckUtils = new $.fn.evenDigitCheckPlugin();
        $.fn.eventsHandlerPlugin({
            selector: "#skzPanel input[type='text']",
            event: 'keyup',
            handler: function () {
                evenDigitCheckUtils.resetState(this);
            },
        });
        $.fn.eventsHandlerPlugin({
            selector: "#skzPanel input[type='text']",
            event: 'change',
            handler: function () {
                evenDigitCheckUtils.resetState(this);
            },
        });
        $.fn.eventsHandlerPlugin({
            selector: '#skzResetButton',
            event: 'click',
            handler: function () {
                evenDigitCheckUtils.resetFields();
            },
        });
        $.fn.eventsHandlerPlugin({
            selector: '#skzButton',
            event: 'click',
            handler: function () {
                evenDigitCheckUtils.skzAction();
            },
        });
    }

    if (!$('.md-disclaimer').hasClass('md-disclaimer-tv')) {
        // If the disclaimer is on TV-Site, then doesn't show it right away. It will only be shown on downloading a video
        $('.md-disclaimer:not(.edit-mode)').popoverPlugin({
            disclaimer: true,
            blurContainer: [$('.main-content')],
        });
    }

    $('.teaser-slider-list:not(.edit-mode)').lightSlider({
        gallery: false,
        auto: false,
        loop: true,
        pauseOnHover: true,
        pager: false,
        item: 3,
        slideMargin: 20,
        prevHtml: '<span class="ic-arrow4-left" aria-hidden="true"></span>',
        nextHtml: '<span class="ic-arrow4-right" aria-hidden="true"></span>',
        responsive: [
            {
                breakpoint: 737,
                settings: {
                    item: 2,
                },
            },
            {
                breakpoint: 569,
                settings: {
                    item: 1,
                },
            },
            {
                breakpoint: 376,
                settings: {
                    item: 1,
                },
            },
        ],
        onSliderLoad: function () {
            $(window).trigger('resize').trigger('scroll');
            setTimeout(function () {
                $(window).trigger('resize').trigger('scroll');
            }, 200);
        },
    });

    $('#fess-bahnhof-search-results').fessBahnhofResultsPlugin({
        lang: language,
    });

    // Plugin für Erzeugung der Tabs-Struktur MUST be before tabsPlugin
    // if (jQuery().createTabsPlugin) {
    // 	//$(".container .row .col-3").createTabsPlugin();
    // }

    /* Plugin für Anzeige von Tabs   */
    $('.tabs').tabsPlugin();

    /* Plugin für Anzeige von Panorama Slider   */
    $('.preview-slider').previewSliderPlugin();

    /* Plugin für zweispaltige Tabellen */
    $('.js-table-division').tableDivisionPlugin();

    /* Plugin für Image Maps */
    $('.js-image-map').imageMapPlugin();

    /* Plugin für Anzeige von Tabs   */
    $('a[data-overlay-url]').iframeOverlayPlugin();

    // parallax plugin load after all others
    //$(".parallax-window").parallax();
    // new parallax
    //$('.x-parallax').parallaxPlugin();

    $('.parallax').xparallaxPlugin();

    //Update langUrl with q, website, dokumenttyp and zeitraum parameters for search
    var qParameter = getURLParameter('q'),
        websiteParameter = getURLParameter('website'),
        documentTypeParameter = getURLParameter('dokumenttyp'),
        timeParameter = getURLParameter('zeitraum');

    if (
        (qParameter != undefined && qParameter != '') ||
        (websiteParameter != undefined && websiteParameter != '') ||
        (documentTypeParameter != undefined && documentTypeParameter != '') ||
        (timeParameter != undefined && timeParameter != '')
    ) {
        $('.langUrl').each(function () {
            var newLangUrl = $(this).attr('href');
            if (qParameter != undefined && qParameter != '') {
                newLangUrl +=
                    (~newLangUrl.indexOf('?') ? '&' : '?') + 'q=' + qParameter;
            }
            if (websiteParameter != undefined && websiteParameter != '') {
                newLangUrl +=
                    (~newLangUrl.indexOf('?') ? '&' : '?') +
                    'website=' +
                    websiteParameter;
            }
            if (
                documentTypeParameter != undefined &&
                documentTypeParameter != ''
            ) {
                newLangUrl +=
                    (~newLangUrl.indexOf('?') ? '&' : '?') +
                    'dokumenttyp=' +
                    documentTypeParameter;
            }
            if (timeParameter != undefined && timeParameter != '') {
                newLangUrl +=
                    (~newLangUrl.indexOf('?') ? '&' : '?') +
                    'zeitraum=' +
                    timeParameter;
            }
            $(this).attr('href', newLangUrl);
        });
    }

    $('#main, .site-nav, .konzern-channel-nav, .konzern-nav')
        .find("[href$='.pdf'],[href$='.jpg'],[href$='.png']")
        .prop('target', '_blank');

    $('#main, .site-nav, .konzern-channel-nav, .konzern-nav')
        .find('a[target=_blank]')
        .each(function (index, element) {
            var title = $(element).attr('title'),
                externalHint = $('body').attr('data-external-hint')
                    ? $('body').attr('data-external-hint')
                    : 'Opens a new window';

            if (title != undefined && title !== '') {
                $(element).attr('title', title + ' - ' + externalHint);
            } else {
                $(element).attr('title', externalHint);
            }
        });

    /* Plugin für Piwi-Extra-Tracking    */
    $('#piwik-tracking').piwikPlugin();

    /* Plugin für Readmore-Boxes    */
    $('.readmore:not(.edit-mode)').readmorePlugin();

    var $searchtextFess = $('#searchtext-fess, #searchtext-fess-bahnhof');

    $searchtextFess.each(function (index) {
        $(this).autocomplete({
            serviceUrl:
                typeof $(this).data('urls-full') !== 'undefined'
                    ? $(this).data('autocomplete-url')
                    : $(this).data('autocomplete-url') +
                      '/fess-autocomplete-' +
                      $(this).data('search-cluster'),
            paramName: 'q',
            params: {
                lang: language,
                topHits: $(this).data('top-hits')
                    ? $(this).data('top-hits')
                    : false,
            },
            dataType: 'json',
            ajaxSettings: {
                jsonp: false,
            },
            triggerSelectOnValidInput: false,
            containerId:
                $(this).attr('id') == 'searchtext-fess-bahnhof'
                    ? 'autocomplete-suggestions-bahnhof'
                    : 'autocomplete-suggestions',
            onSelect: function (suggestion) {
                $('#searchtext-fess').val(suggestion.value);
                $('#header-search-form').submit();
            },
        });
    });

    var $spsearchtextFess = $('#spsearchtext-fess');
    $spsearchtextFess.autocomplete({
        serviceUrl:
            typeof $spsearchtextFess.data('urls-full') !== 'undefined'
                ? $spsearchtextFess.data('autocomplete-url')
                : $spsearchtextFess.data('autocomplete-url') +
                  '/fess-autocomplete-' +
                  $spsearchtextFess.attr('data-search-cluster'),
        paramName: 'q',
        params: {
            lang: language,
            topHits: $spsearchtextFess.data('top-hits')
                ? $spsearchtextFess.data('top-hits')
                : false,
        },
        dataType: 'json',
        ajaxSettings: {
            jsonp: false,
        },
        triggerSelectOnValidInput: false,
        containerId: 'spautocomplete-suggestions',
        onSelect: function (suggestion) {
            $spsearchtextFess.val(suggestion.value);
            $('#sp-search-form').submit();
        },
    });

    $('#fess-search-results').fessSearchResultsPlugin({
        lang: language,
        serviceFullUrl:
            typeof $spsearchtextFess.data('urls-full') !== 'undefined'
                ? $spsearchtextFess.data('search-url')
                : null,
    });

    var kartenLegendeList = $('.karten-legende');
    $.each(kartenLegendeList, function (index, list) {
        $.each($(list).find('li'), function (index1, listItem) {
            var num = index1 + 1 > 9 ? index1 + 1 : '0' + (index1 + 1);
            $(listItem).prepend(
                '<span class="line"></span><span>' + num + '</span>'
            );
        });
    });

    //   Sortierung für DMS-Tabellen.
    var tableSorterTextExtractionNew = function (node) {
        var output = node.innerText.trim();

        if (output.indexOf('.') == 0) {
            output = '0';
        } else if (output.length == 0) {
            output = '0';
        }

        return output;
    };

    if ($('.md-dms table th.download-header').length > 0) {
        // The table has checkboxes for download, which means the first column should not be sortable
        $('.md-dms table').tablesorter({
            widgets: ['staticRow'],
            dateFormat: 'ddmmyyyy',
            textExtraction: tableSorterTextExtractionNew,
            headers: { 0: { sorter: false } },
        });
    } else {
        // The table doesn't have checkboxes for download, so all the columns should be sortable
        $('.md-dms table').tablesorter({
            widgets: ['staticRow'],
            dateFormat: 'ddmmyyyy',
            textExtraction: tableSorterTextExtractionNew,
        });
    }

    function handleTeaserHeadlineHeight() {
        // Teaser Slider Heights
        $('.md-teaser-slider').each(function () {
            var maxHeightCat = 0;
            var maxHeightTitle = 0;

            $(this)
                .find('.teaser-slider-cat')
                .each(function () {
                    if ($(this).height() > maxHeightCat) {
                        maxHeightCat = $(this).height();
                    }
                });

            $(this).find('.teaser-slider-cat').height(maxHeightCat);

            //////

            $(this)
                .find('.teaser-slider-title')
                .each(function () {
                    if ($(this).height() > maxHeightTitle) {
                        maxHeightTitle = $(this).height();
                    }
                });

            $(this).find('.teaser-slider-title').height(maxHeightTitle);
        });
    }

    $(window).resize(function () {
        handleTeaserHeadlineHeight();
    });

    $('.md-dms table').dmsTablePlugin();

    $('div.md img[src*="webcam.jpg"], .js-webcam').webcamPlugin();

    $('.js-co2-count').co2Plugin({ locale: language });

    var selectorsForLegacyWebComponents = [
       // 'oebb-fm-app-form',
      //  'oebb-fm-app-newsletter',
       // 'oebb-fm-form',
       // 'oebb-from-to-form',
       // 'oebb-date-field',
       // 'oebb-delay-confirmation',
    ];

    const selectorForStencil = [
        'corp-apartment-application',
        'corp-apartment-application-button',
        'corp-apartment-list',
        'corp-apartment-search',
        'sbahn-resident-info-list',
        'oebbx-app-countdown',
        'oebbx-app-lokfinder',
        'oebbx-before-after-slider',
        'oebbx-gallery-slider',
        'oebbx-gallery-thumbs',
        'oebbx-cloud-image',
        'corp-euro-2024',
        'corp-euro-2024-next-games',
        'corp-balloon-animation',
        'corp-snow-animation',
        'corp-fleet',
        'corp-number-animation',
    ].join(',');

    if (document.querySelector(selectorForStencil)) {
        // Load the corp-2016 stencil components (including oebb-web-components)
        loadScript(
            getResourceUrl('stencil/corp-2016/corp-2016.esm', '.js'),
            null,
            'corp-2016-module',
            'module'
        );
    }

    if (document.querySelector('.js-rcg-transfer')) {
        loadScript('/.resources/corp-2016/themes/js/rcg-transfer.min.js');
    }

    if (document.querySelector('.js-werbung-ad-spaces')) {
        loadScript('/.resources/corp-2016/themes/js/werbung-ad-spaces.min.js');
    }

    if (document.querySelector('.js-poi-map')) {
        loadScript('/.resources/corp-2016/themes/js/poi-map.min.js');
    }
    if (
        document.querySelector('.js-leaflet-map')
        || document.querySelector('.js-leaflet-map-apartment')
    ) {
        // TODO: remove after debugging
        // loadScript("/.resources/corp-2016/themes/js/leaflet-map.min.js?t=" + Date.now());
        loadScript('/.resources/corp-2016/themes/js/leaflet-map.min.js');
    }

    if (document.querySelector('.js-md-s-bahn-map')) {
        loadScript('/.resources/corp-2016/themes/js/main-s-bahn-map.min.js');
    }

    if (document.querySelector('.js-animation-lottie')) {
        loadScript('/.resources/corp-2016/themes/js/animation-lottie.min.js');
    }

    if (document.querySelector('.js-socialmedia-wall')) {
        loadScript('/.resources/corp-2016/themes/js/socialmedia-wall.min.js');
    }

    if (document.querySelector('#js-mapplic')) {
        loadScript(
            '/.resources/corp-2016/themes/js/jquery-mousewheel.min.js',
            function () {
                loadCss('/.resources/corp-2016/themes/mapplic/mapplic-min.css');
                loadScript(
                    '/.resources/corp-2016/themes/mapplic/mapplic.min.js',
                    function () {
                        var css =
                            '.mapplic-filtered svg [id^=landmark] > * {opacity: 1 !important; }';
                        var jsonDataUrl = $('#js-mapplic').data('url');

                        if (language == 'de') {
                            window.mapplic_localization = {
                                more: 'Info',
                                search: 'Suche',
                                zoomin: 'Zoom rein',
                                zoomout: 'Zoom raus',
                                resetzoom: 'Zoom zurücksetzen',
                                levelup: 'Level up',
                                leveldown: 'Level down',
                                clearsearch: 'Suche zurücksetzen',
                                closepopup: 'Fenster schließen',
                                clearfilter: 'Filter zurücksetzen',
                            };
                        }

                        var map = $('.js-mapplic').mapplic({
                            source: jsonDataUrl,
                            customcss: css,
                            sidebar: true,
                            sidebartoggle: true,
                            alphabetic: true,
                            developer: true,
                            searchdescription: true,
                            searcheverywhere: true,
                            animations: true,
                            minimap: false,
                            marker: 'hidden',
                            fillcolor: false,
                            fullscreen: false,
                            developer: false,
                            thumbholder: true,
                            maxscale: 3,
                        });


                        $('.js-mapplic-legend-item').on('click', function() {
                            var self = map.data("mapplic");
                            var shopId = $(this).data("target");

                            self.showLocation(shopId, 100);
                        })

                    }
                );
            }
        );
    }

    if (document.querySelector('oebb-popover')) {
        loadScript('/.resources/corp-2016/themes/js/Popover.js', null, 'popover', 'module');
    }

    if (document.querySelector("[data-matomo-tag-manager-event-name]")) {
        loadScript("/.resources/corp-2016/themes/js/main-MatomoTagManagerIntegration.min.js");
    }

    if (document.querySelector("[data-matomo-cornerstone-campaign-tracking]")) {
        loadScript("/.resources/corp-2016/themes/js/main-MatomoCampaignUtilities.min.js");
    }

    if (document.querySelector(".slider-full-width")) { 
        setSliderFullWidth();
    }
}

$('a.has-anchor').on('click', function (event) {
    var $target = $('a[name="' + this.hash.replace('#', '') + '"]');

    if ($target.length == 1) {
        event.preventDefault();
        event.stopPropagation();
        scrollToTarget($target);
    }
});

$(function () {
    jqueryOnDomReady();
});

// wrapper for responsive tables (horizontal scrolling)
function wrapTables() {
    $('table').each(function () {
        if (!$(this).parent().hasClass('table-wrapper')) {
            $(this).wrap('<div class="table-wrapper"></div>');
        }
    });
}

$(document).ready(function () {
    $('body').addClass('dom-ready');

    if (window.location.hash) {
        var $element = $('a[name="' + window.location.hash.substring(1) + '"]');

        if ($element.length == 1) {
            scrollToTarget($element);
        }
    }

    //Custom Table OEBB Icons
    $('.oebb-icon').closest('table').addClass('has-icon');

    //Remove span.shy and keep soft-hyphen
    $('span.shy').each(function () {
        var element = $(this).parent();
        $(this).replaceWith('&shy;');
        //replace html with new html, so strings are kept together
        element.html(element.html());
    });

    // suedstrecke, rausragende Bilder
    $('.sticking-out').each(function (i, elem) {
        var $img = $(elem).find('img').first();

        var backgroundURL = $img.data('src')
            ? $img.data('src')
            : $img.attr('src');

        $img.css('opacity', '0');
        $(elem).css('background-image', 'url(' + backgroundURL + ')');
    });

    //Icons im Main mit aria-hidden versehen
    $('main [class^="ic-"], main [class*=" ic-"]').attr('aria-hidden', 'true');

    // RCG Glossar Links target "_blank"
    $('.js-rcg-glossary-term-description a').attr('target', '_blank');

    // call function for table wrapping
    wrapTables();
});

function setSliderFullWidth() {
    const observer = new MutationObserver((mutations, obs) => { 
        const mainContent = document.querySelector(".main-content");
        const slider = document.querySelector(".slider-full-width");

        if (mainContent && slider && !slider.classList.contains("edit-mode")) {
            mainContent.prepend(slider);
            obs.disconnect();
        }
    });

    observer.observe(document.body, { childList: true, subtree: true });
}